<template>
  <section>
    <div style="display:flex;" >
        <el-cascader  v-model="taskClassify"
          style="width:150px;"
          placeholder="任务类型"
          :options="ClassifyModules"
          :show-all-levels="false"
          :props="{ checkStrictly: true }"
          clearable></el-cascader>
          <el-date-picker v-model="madedate"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="任务完成日期"
                          end-placeholder="任务完成日期">
          </el-date-picker>
        <!--工具条-->
        <toolbar :buttonList="buttonList"
                @callFunction="callFunction"
                :buttonListmsg="buttonListmsg"></toolbar>
    </div>
    <!--列表-->
    <el-table :data="users"
              highlight-current-row
              row-key="Id"
              lazy
              :load="load"
              :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
              :fit="true"
              @current-change="selectCurrentRow"
              @row-dblclick="checkInfo"
              :row-style="{height:'55px'}"
              :cell-style="{padding:'0px'}"
              @cell-mouse-enter="tabmouseEnter"
              @cell-mouse-leave="tabmouseLeave"
              :header-cell-style="{background:'#dce4f1',color:'#000000'}"
              style="width: 100%">
      <!-- <el-table-column type="index"></el-table-column> -->
      <el-table-column type="index" width="6">
        <template scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="工作项目"
                       prop="WorkItem"
                       min-width="400px">
        <template slot-scope="scope">
          <!-- <i class="el-icon-circle-check"
             v-if="scope.row.IsHasChildren == true"></i>
          <i class="el-icon-circle-check"
             v-if="localuser != scope.row.PersonOfDuty && scope.row.IsHasChildren == false"></i> -->
          
             <!-- <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button> -->
             <span :style="{'cursor':'default'}">{{scope.row.Number}}、{{scope.row.WorkItem}}
              <el-button
                @click="handleCollect(scope.row)"
                style="color: #ff9800; margin-right: 3px"
                size="mini"
                v-show="(collectBtn && collectRowId == scope.row.Id) || scope.row.CollectId != null"
                :title="scope.row.CollectId == null ? '特别关注' : '已特别关注'"
                type="default"
                :icon="scope.row.CollectId == null ? 'el-icon-star-off' : 'el-icon-star-on'"
                circle
              ></el-button>
             </span>
            <!-- <span v-if="!scope.row.WorkDescription" >{{scope.row.WorkItem}}</span>
            <el-tooltip v-else placement="right-start" effect="light" :enterable="true">
              <div slot="content" v-html="'<span style=\'color:#999\' >工作项目:</span><br/>'+scope.row.WorkItem+ '<br/><span style=\'color:#999\' >工作描述:</span><br/>'+scope.row.WorkDescription.replace(/\n/g,'<br/>')" ></div>
              <el-button style="color:black;cursor:default" type="text">{{scope.row.WorkItem}}</el-button>
            </el-tooltip> -->
        </template>
      </el-table-column>
      <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
      <el-table-column label="计划完成时间"
                       prop="PlanComplateTime"
                       :formatter="formatPlanComplateTime"
                       min-width="120px">
        <template slot-scope="scope">
          <div style="font-size:9px;">
            <p>计划完成时间:{{  formatPlanComplateTime(scope.row) }}</p>
            <p>&nbsp;&nbsp;完成时间:{{  formatComplatedTime(scope.row) }}</p>
          </div>
        </template>     
      </el-table-column>
      <el-table-column label="责任人"
                       prop="PersonOfDuty"
                       min-width="70px;"></el-table-column>
      <el-table-column label="任务状态"
                       prop="Status"
                       min-width="100px;">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">
              已完成{{  scope.row.TaskProgressValue }}%
            </div>
            <div>
              <el-tag effect="dark"
                      type="success">已完成</el-tag>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="任务类型"
                       prop="CommonTaskClassifyText"
                       :formatter="formatCommonTaskClassifyText"
                       min-width="80px;"></el-table-column>
      <!-- <el-table-column label="任务来源"
                       prop="Source"
                       :formatter="formatSource"
                       min-width="80px;"></el-table-column> -->

      <el-table-column label="任务来源"
                       prop="Source"
                       :formatter="formatSource"
                       min-width="90px">
        <template slot-scope="scope">
          <div style="font-size:10px">{{formatSource(scope.row)}}</div>
          <div v-if="scope.row.AssignName" style="font-size:10px">由{{scope.row.AssignName}}指派</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="指派人"
                       prop="AssignName"
                       min-width="60px;"></el-table-column> -->
      <el-table-column label="创建时间"
                       prop="CreateTime"
                       :formatter="formatCreateTime"
                       min-width="100px;">
        <template slot-scope="scope">
          <el-tooltip placement="left">
            <div slot="content">
              {{ formatTipCreateTime( scope.row) }}
            </div>
            <div>
              {{ formatCreateTime( scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column label="完成时间"
                       prop="ComplatedTime"
                       min-width="190px;"></el-table-column> -->
      <!-- <el-table-column label="完成后需审核" prop="IsNeedApproval" min-width="100px;">
                    <template slot-scope = "scope">
                    <el-tag :type = "scope.row.IsNeedApproval ? 'danger' : 'success'"  disable-transitions>
                        {{scope.row.IsNeedApproval ? "是" : "否"}}
                    </el-tag>
                    </template>
                </el-table-column> -->
      <!-- <el-table-column label="备注"  width="100"  prop="Remark"></el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="scope">
            <!-- <el-button type="text"
                       size="medium"
                       @click="checkInfo(scope.row)">查看</el-button>
          <br/>
          <el-tooltip content="点此关联年度计划" placement="left" effect="light">
            <el-button size="medium" type="text"
                  @click="yearplanchoose(scope.row)">{{scope.row.TagName==null?'关联':scope.row.TagName}}</el-button>
          </el-tooltip> -->


          <p><el-link type="primary" :underline="false" @click="checkInfo(scope.row)" v-if="scope.row.Status != 5">查看</el-link></p>
          <p>
            <el-tooltip :content="scope.row.TagName==null?'点此关联年度计划':scope.row.TagName" placement="left" effect="light">
              <el-tag size="mini" v-if="scope.row.PersonOfDuty==localuser" :type="scope.row.TagName==null?'warning':'success'"  @click="yearplanchoose(scope.row)" effect="plain" ><span style="cursor:default;">{{scope.row.TagName==null?'待关联':'已关联'}}</span></el-tag>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column label="年度计划"
                       prop="TagName"
                       min-width="60px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button size="medium"
                     type="text"
                     @click="yearplanchoose(scope.row)">{{scope.row.TagName==null?'关联':scope.row.TagName}}</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="任务评分"
                       prop="Score"
                       min-width="70px" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.Score==0"></div>
          <div v-else-if="scope.row.Score==1">A</div>
          <div v-else-if="scope.row.Score==2">B</div>
          <div v-else-if="scope.row.Score==3">C</div>
          <div v-else-if="scope.row.Score==4">D</div>
        </template>
      </el-table-column>
      <el-table-column label="评分意见" prop="ScoreContent" min-width="100px"></el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>


    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>




    <!--关联年度计划-->
    <el-dialog :visible.sync="NiandujihuaVisible"
               v-model="NiandujihuaVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;cursor:default;">关联我的年度计划</span>
      </template>
      <el-form :model="yearplanForm"
               ref="yearplanForm"
               label-width="85px"
               :rules="yearplanFormRules">
        <el-form-item label="年度计划"
                      prop="YearPlan">
          <el-select v-model="yearplanForm.YearPlan" placeholder="请选择你的年度计划任务进行关联"
                     @change="YearPlanchange" style="width:100%;">
            <el-option v-for="item in YearPlanTaskList"
                       :key="item.Id"
                       :value="`${item.YearPlanId}|${item.TaskId}|${item.WorkItem}`"
                       :label="item.WorkItem">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary" @click="yearplanSubmit">提交</el-button>
        <el-button type="goon" @click="NiandujihuaVisible=false">关闭</el-button>
      </div>
    </el-dialog>

  </section>
</template>
<script>
import util from '../../../util/date';
import { AddTaskCollect,RemoveTaskCollect,SetTaskAboutYearPlan,GetYearPlanListByUserCode,QueryPageComplatedTasksByUserCode, GetClassifyList, QueryTaskProgressByTaskId, QueryChildTasksByParentId, ActivateTask, QueryTasksById, AddTaskProgressReview, QueryReviewByProgressId } from '../../api/oa';
import taskinfo from "../../components/taskinfo.vue"
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
export default {
  components: { taskinfo, Toolbar },
  data () {
    return {
      collectBtn: false,
      collectRowId: '',
      currentActiveId: null,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      madedate: null,
      taskClassify: [],
      ClassifyModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "日常工作",
          children:[{
              value: 11,
              label: '日常工作'
          },{
              value: 12,
              label: '流程批复'
          },{
              value: 13,
              label: '临时工作'
          },{
              value: 14,
              label: '月度计划'
          },{
              value: 15,
              label: '会议项目'
          }]
        },
        {
          value: 3,
          label: "例会项目"
        },
        {
          value: 4,
          label: "年度计划"
        },
        {
          value: 5,
          label: "专项会议"
        },
      ],
      buttonList: [],
      localuser: null,
      content:'',
      mapReview:new Map(),
      users: [],
      tasks: [],
      NiandujihuaVisible: false,
      YearPlanTaskList: [],       //年度计划列表
      yearplanForm: {
        YearPlan: null,
        YearplanId: null,
        TaskId: null,
        WorkItem: null
      },
      yearplanFormRules: {
        YearPlan: [
          { required: true, message: '请选择需关联的年度计划', trigger: ["blur", 'change'] }
        ]
      },
      CommonTaskClassifyList: [],
      CommonTaskClassifyText: null,
      currentRow: null,
      buttonListmsg: 'taskmanage',
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      checkVisible: false,
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      pValue1: null,   //进度条数值
      customColor: '#6f7ad3',   //进度条颜色
      filters: {
        name: ''
      },
      checkForm: {           //查看表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null

      }
    }
  },
  methods: {
    handleCollect(row) {
      if (row.CollectId == null) {
        var para = {}
        para.TaskID = row.Id
        var user = JSON.parse(window.localStorage.user)
        para.CreatedId = user.sub
        para.CreatedBy = user.name
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          })
          // console.log(res);
          var id = res.data.response
          row.CollectId = id
        })
      } else {
        this.$confirm('确定要关闭对此任务的关注吗？', '提示', {})
          .then(() => {
            var para = {}
            para.Id = row.CollectId
            para.TaskID = row.Id
            RemoveTaskCollect(para).then((res) => {
              this.$message({
                message: '已取消关注',
                type: 'success'
              })
              row.CollectId = null
            })
          })
          .catch(() => {})
      }
    },
    tabmouseEnter(row, column, cell, event) {
      if (column.property != 'WorkItem') return
      this.collectBtn = true
      this.collectRowId = row.Id
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event) {
      this.collectBtn = false
      this.collectRowId = ''
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    //关联年度计划
    YearPlanchange (val) {
      this.yearplanForm.YearplanId = val.split('|')[1];
      this.yearplanForm.WorkItem = val.split('|')[2];
    },
    yearplanchoose (row) {
      if(row.TaskClassify==4)
      {
        this.$message({
          message: "该任务本身为年度计划任务，请进入年度计划页面进行关联操作",
          type: "warning"
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if (row.PersonOfDutyCode == user.sub) {
        this.yearplanForm.TaskId = row.Id;
        this.NiandujihuaVisible = true;
      } else {
        this.$message({
          message: "非任务负责人无法进行关联操作",
          type: "warning"
        });
      }
    },
    //关联年度计划提交
    yearplanSubmit () {
      this.$refs.yearplanForm.validate(valid => {
        if (valid) {
          let para = { taskId: this.yearplanForm.TaskId, yearplanId: this.yearplanForm.YearplanId }
          this.$confirm("确定关联此年度计划吗？", "提示", {}).then(() => {
            SetTaskAboutYearPlan(para).then(res => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                });
                this.currentRow.TagName=this.yearplanForm.WorkItem;
                this.$refs.yearplanForm.resetFields();
                this.NiandujihuaVisible = false;
                this.getComplatedTasks();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              }
            })
          });
        }
      })
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    load (tree, treeNode, resolve) {
      let para = {
        taskId: tree.Id,
      };
      QueryChildTasksByParentId(para).then((res) => {
        var myarr = new Array();
        var number = 0;
        res.data.response.forEach(element => {
            element.Number = ++number;
            myarr.push(element)
        })
        resolve(myarr);
        // resolve(res.data.response)
      });
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatComplatedTime: function (row, colume) {
      return (!row.ComplatedTime || row.ComplatedTime == '') ? '' : util.formatDate.format(new Date(row.ComplatedTime), 'yyyy-MM-dd');
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      // var user = JSON.parse(window.localStorage.user);
      // if (row.PersonOfDuty != user.uRealName) {
      //     return 'success-row';
      //  }else{
      //       return '';
      //  } 
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getComplatedTasks();
    },
    getComplatedTasks () {
      var user = JSON.parse(window.localStorage.user);

      let begintime = "";  //开始时间
      let endtime = "";    //结束时间
      if (this.madedate != null) {
        switch (this.madedate.length) {
          case 1:
            begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
            break;
          case 2:
            begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
            endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
            break;
          default:
            break;
        }
      }
      let para = {
        taskClassify :this.taskClassify.length>1?this.taskClassify[1]:this.taskClassify[0],
        complatedStart: begintime,
        complatedEnd: endtime,
        userCode: user.sub,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name
      };
      QueryPageComplatedTasksByUserCode(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        var arr=new Array();
        var number = (this.page-1)*this.pageSize;
        res.data.response.data.forEach(element => {
          // console.log(element);
          element.Number=++number;
          // element.MarginLeft=3;
          element.Level = 1;
          // this.mapLevel.set(element.Id,1);
          arr.push(element)
        });
        this.users = arr;
        // this.users = res.data.response.data;
      });
    },
    //双击查看详细信息
    checkInfo (row) {
      this.checkVisible = true;
      this.checkForm = row;
      return;
      var ucode = JSON.parse(window.localStorage.user);
      if (row.TaskClassify == 1) {
        this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        if (row.PersonOfDuty != ucode.name) {
          this.showAssign = true;
        } else {
          this.showAssign = false;
        }
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != ucode.name) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != ucode.name) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != ucode.name) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          if (row.PersonOfDuty != ucode.name) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != ucode.name) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.Id, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        this.checkForm.Files = [];
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      this.pValue1 = row.TaskProgressValue;
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
      // this.checkForm = Object.assign({},row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        // this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("complatetaskdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
      // this.getComplatedTasks();
    },
    handleActive () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要激活的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      }
      if (row.TaskClassify != 1) {
        this.$message({
          message: "当前任务暂不能激活！",
          type: "error",
          duration: 10000
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      let para = { taskId: row.Id, userCode: user.sub, userName: user.name };
      ActivateTask(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.getComplatedTasks();
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
  },
  mounted () {
    this.getComplatedTasks();
    var user = JSON.parse(window.localStorage.user);
    let para = { userCode: user.sub };
    GetYearPlanListByUserCode(para).then((res) => {
      this.YearPlanTaskList = res.data.response;
      this.YearPlanTaskList.push({Id:null,WorkItem:'暂不关联',YearPlanId:null});
    });
    this.localuser = user.name;
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  }
}
</script>
<style scoped>
.complatediv,
.complatecard {
  height: calc(100vh - 220px);
}
.complatetaskdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>